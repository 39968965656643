<script setup>
const props = defineProps({
    id: {
        type: String,
        default: null,
    },
    title: {
        type: String,
        default: null,
    },
    subtitle: {
        type: String,
        default: null,
    },
})
</script>

<template>
    <v-row :id="id">
        <v-col cols="12" class="pb-0 text-center st-header">{{ title }}</v-col>
        <v-col cols="12" class="pt-0 text-center st-subheader">{{ subtitle }}</v-col>
    </v-row>
</template>

<style scoped></style>
